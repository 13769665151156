import * as React from "react";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import SEO from '../components/SEO'

const GetBlogged = () => {
  const { getBlogged } =
    useStaticQuery(graphql`
      query GetBloggedImage {
        getBlogged: file(relativePath: { eq: "annie-gray-WEWTGkPUVT0-unsplash.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 800
              height: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        } 
      }
    `);
  const getBloggedImageData = getImage(getBlogged)

  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"Get Blogged LocalTier"}
        description={"Put Your Local Business in the Spotlight! Reach New Customers and Enhance Your Search Engine Ranking Naturally!"}
      />
      <GatsbyImage
        image={getBloggedImageData}
        className="h-64 md:h-72 w-full object-cover"
      />

      <section className="bg-white">
        <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 sm:py-12 lg:px-8">
          <div className="py-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-3">
              Put Your Local Business in the Spotlight!        </h1>
            <h2 className="text-lg sm:text-xl md:text-2xl font-display font-normal text-gray-700 mb-3">Reach New Customers and Enhance Your Search Engine Ranking Naturally! </h2>
            <p className="text-gray-500 font-normal mb-6 leading-relaxed">
              Whether you’re a business owner, a manager or a marketing professional looking for consistent, high-quality content to naturally boost your website presence, we have a solution for you!
            </p>
            <p className="text-gray-500 font-normal mb-6 leading-relaxed">These days, it’s not enough to simply have a website. It’s also not enough to fill that site with content. Search engines demand high quality content. But while there are tons of content writers out there, very few of them write things that <i>people actually want to read.</i> </p>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">Today’s local business content has to juggle many responsibilities at once. It has to be engaging and interesting enough to read. It needs to explain to the reader exactly what’s in it for them and what they get out of doing business with your company. It needs to be compelling enough to convert visitors into leads and make people want to learn more. </p>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">And last but not least, it has to be powerful enough to make people want to share it when others inevitably ask for recommendations on social media </p>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">If your local business isn’t coming up front-of-mind when it comes to these key points, Local Tier can help. Our writers are local, not thousands of miles away, so they can easily get to know your business and how it works. </p>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">What’s more, they’re as much a part of the local community as you are. They’re able to write press releases, sales announcements, new product or service launches and spread the word about your business so that you can reach new customers, tap into new markets and get the exposure that your business deserves. </p>
            <h3 className="text-lg md:text-xl text-gray-800 font-semibold mb-3 leading-relaxed">What Kind of Local Content Helps Convert Visitors to Customers?</h3>
            <p className="text-gray-500 font-normal mb-6 leading-relaxed">You may be surprised to learn that a wide variety of content has the potential to convert visitors to customers, including:

              <ul><li>Customer reviews</li>
                <li>Testimonials</li>
                <li>Interviews with customers or employees</li>
                <li>Tips or tutorials and how to get the most out of your product or service</li></ul>
            </p>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">In addition, our comprehensive content can also help you build and reinforce your digital strategy as well as your brand identity, adding powerful and convincing reasons why local customers in California should do business with you. </p>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">This in-depth approach helps to gradually improve your search engine ranking while maximizing your visibility in your industry and positioning you as a recognized authority in your local community. </p>
            <h3 className="text-lg md:text-xl text-gray-800 font-semibold mb-3 leading-relaxed">More than Just Writers</h3>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">At Local Tier, you’re working with so much more than just writers. Consider our marketing professionals an extension of your own team -- people who are interested in spreading the word about your business by creating high quality, share-worthy content that’s ready to unleash on social media, blogs, news outlets and more. </p>

            <p className="text-gray-500 font-normal mb-6 leading-relaxed">We know what it takes to make your business shine online. Start today by filling out the form below!</p>



          </div>
        </div>

      </section>





    </Layout>
  );
};

export default GetBlogged;
